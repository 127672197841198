import { DatabaseModels } from "../lib/db";
import { Product } from "../lib/db/product";
import { Paginated } from "../lib/db/root";
import { generateBlurhashDataUrl } from "../utils/blurhash";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";
import { formatToRupiah } from "../utils/string";

const getPaginated = (
  db: DatabaseModels,
  page: number = DEFAULT_CURRENT_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE
): Paginated<string[]> => {
  const { data, metadata } = db.products.getPaginated(page, pageSize);
  const items = data?.map((d: Product) => [
    d.id.toString(),
    d.name,
    d.category,
    formatToRupiah(d.price),
  ]);

  return { data: items, metadata };
};

export type ProductMedia = {
  image: {
    url: string;
    dataUrl: string;
    alt: string;
  };
  price: number;
  secondaryPrice: number;
  category: "juice" | "dessert" | "food";
  name: string;
};

const convertProductToProductMedia = async (
  product: Product
): Promise<ProductMedia> => {
  const dataUrl = await generateBlurhashDataUrl(product.blurhash, 32, 32);

  return {
    image: {
      url: product.imageUrl,
      dataUrl,
      alt: product.name,
    },
    price: product.price,
    secondaryPrice: product.secondaryPrice,
    category: product.category,
    name: product.name,
  };
};

export const ProductService = {
  getPaginated,
  getFeatured: async (db: DatabaseModels) => {
    const products = db.products.getFeatured();
    const featuredProducts: ProductMedia[] = [];

    for (const product of products) {
      const productMedia = await convertProductToProductMedia(product);
      featuredProducts.push(productMedia);
    }

    return featuredProducts;
  },

  getJuices: async (db: DatabaseModels) => {
    const juices = db.products.getJuices();
    const juicesProducts: ProductMedia[] = [];

    for (const product of juices.data) {
      const productMedia = await convertProductToProductMedia(product);
      juicesProducts.push(productMedia);
    }

    return juicesProducts;
  },

  getDesserts: async (db: DatabaseModels) => {
    const desserts = db.products.getDesserts();
    const dessertsProducts: ProductMedia[] = [];

    for (const product of desserts.data) {
      const productMedia = await convertProductToProductMedia(product);
      dessertsProducts.push(productMedia);
    }

    return dessertsProducts;
  },

  getFoods: async (db: DatabaseModels) => {
    const foods = db.products.getFoods();
    const foodsProducts: ProductMedia[] = [];

    for (const product of foods.data) {
      const productMedia = await convertProductToProductMedia(product);
      foodsProducts.push(productMedia);
    }

    return foodsProducts;
  },
};
