import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import ContactForm from "../components/contactForm";
import { Head } from "../components/head";
import { databaseModels } from "../lib/db";
import { SubmissionCategory } from "../lib/db/contactSubmission";
import { checkMutationAttempts } from "../lib/rateLimiter";
import { Contact } from "../pages/contact";
import { contactSubmissionValidation } from "../validation/contactSubmission";

export const contactController = new Elysia().group("/contact-us", (app) =>
  app
    .decorate("db", databaseModels)
    .get("/", (): JSX.Element => {
      const title = "Jus Kode - Hubungi Kami";

      return (
        <Head title={title}>
          <Contact />
        </Head>
      );
    })
    .post(
      "/",
      async ({ db, body, request, server, set }): Promise<JSX.Element> => {
        try {
          await checkMutationAttempts(request, server, "contact-submission");
          db.contactSubmission.create({
            ...body,
            category: body.category as SubmissionCategory,
          });

          const notificationPayload = {
            event: "notification",
            type: "success",
            message: "Pesan Anda telah terkirim, silahkan tunggu balasan kami.",
          };
          set.headers["HX-Trigger"] = JSON.stringify(notificationPayload);

          return <ContactForm />;
        } catch (error) {
          throw error;
        }
      },
      { ...contactSubmissionValidation }
    )
);
