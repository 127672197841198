import { Database } from "bun:sqlite";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { Paginated } from "./root";

export interface Investment {
  id: string;
  branchName: string;
  totalInvestment: number;
  totalInvestmentCollected: number;
  location: string;
  description: string;
  isActive: boolean;
  images: InvestmentImage[];
  createdAt: Date;
  updatedAt: Date;
}

export interface InvestmentImage {
  id: string;
  investmentId: string;
  imageUrl: string;
  blurhash: string;
}

export class InvestmentsDatabase {
  protected db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  getList(): Investment[] {
    const results = this.db
      .query(`SELECT * FROM investments WHERE is_active = 1`)
      .all() as object[];
    const investments = results.map(toCamelCase) as Investment[];
    this.retrieveInvestmentImages(investments);

    return investments;
  }

  // Get investments paginated
  getPaginated(page: number, pageSize: number): Paginated<Investment> {
    const results = this.db
      .query(
        `SELECT * FROM investments WHERE is_active = 1 ORDER BY updated_at DESC LIMIT ? OFFSET ?`
      )
      .all(pageSize, page * pageSize) as object[];
    const investments = results.map(toCamelCase) as Investment[];
    this.retrieveInvestmentImages(investments);

    const totalRows = this.db
      .query(`SELECT COUNT(*) as total FROM investments`)
      .get() as { total: number };

    const metadata = constructPaginationMetadata(totalRows, page, pageSize);
    return { data: investments, metadata };
  }

  retrieveInvestmentImages(investments: Investment[]) {
    const investmentIds = investments.map((investment) => investment.id);
    const images = this.db
      .query(
        `SELECT * FROM investment_images WHERE investment_id IN (${investmentIds
          .map(() => "?")
          .join(",")})`
      )
      .all(...investmentIds) as object[];

    const investmentImages = images.map(toCamelCase) as InvestmentImage[];
    const imagesByInvestmentId = investmentImages.reduce((acc, image) => {
      const { investmentId } = image;
      if (!acc[investmentId]) {
        acc[investmentId] = [];
      }
      acc[investmentId].push(toCamelCase(image));
      return acc;
    }, {} as { [key: string]: InvestmentImage[] });

    for (const investment of investments) {
      investment.images = imagesByInvestmentId[investment.id] || [];
    }
  }
}
