import { Database } from "bun:sqlite";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { Paginated } from "./root";

export interface Career {
  id: string;
  name: string;
  division: string;
  location: string;
  requirements: string;
  email: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class CareersDatabase {
  protected db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  getList(): Career[] {
    const query = `SELECT * FROM careers WHERE is_active = 1`;
    const results = this.db.query(query).all() as object[];

    return results.map(toCamelCase) as Career[];
  }

  // Add a new method for full-text search
  search(query: string): Career[] {
    // Split the query into words and add wildcards to each word
    const processedQuery = query
      .trim()
      .replace(/[^a-zA-Z]/g, "")
      .split(/\s+/)
      .map((word) => `${word}*`)
      .join(" ");

    const results = this.db
      .query(
        `
        SELECT c.* 
        FROM careers c
        INNER JOIN careers_fts fts ON c.rowid = fts.rowid
        WHERE careers_fts MATCH ?
        ORDER BY rank
      `
      )
      .all(processedQuery) as object[];

    return results.map(toCamelCase) as Career[];
  }
}
