import { LogData } from "./logData";

export class LogUtil {
  static formatError(error: Error, code: string, path: string): LogData {
    return {
      error: {
        code,
        path: path,
        stack_trace: error.stack,
      },
    };
  }
}
