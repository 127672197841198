import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { MediaProps } from "../components/banner";
import { Head } from "../components/head";
import { databaseModels } from "../lib/db";
import { Home } from "../pages/home";
import { ProductService } from "../services/product";
import { generateBlurhashDataUrl } from "../utils/blurhash";

export const homeController = new Elysia().group("/", (app) =>
  app
    .decorate("db", databaseModels)
    .get("/", async ({ db }): Promise<JSX.Element> => {
      const title = "Jus Kode";

      const banners = db.banners.getActive("main");
      const media: MediaProps[] = [];

      for (const banner of banners) {
        if (banner.mediaType === "image") {
          const dataUrl = await generateBlurhashDataUrl(banner.blurhash);
          media.push({
            type: "image",
            image: {
              url: banner.mediaUrl,
              dataUrl,
              alt: banner.name,
              width: 1440,
            },
          });
        } else {
          media.push({
            type: "video",
            video: {
              url: banner.mediaUrl,
              width: 1280,
              height: 720,
            },
          });
        }
      }

      const featuredProducts = await ProductService.getFeatured(db);

      return (
        <Head title={title}>
          <Home media={media} products={featuredProducts} />
        </Head>
      );
    })
);
