import { Html } from "@elysiajs/html";

const ChevronDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1994_909)">
        <path
          d="M6.175 7.15845L10 10.9751L13.825 7.15845L15 8.33345L10 13.3334L5 8.33345L6.175 7.15845Z"
          fill="#080804"
        />
      </g>
      <defs>
        <clipPath id="clip0_1994_909">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChevronDown;
