import { Database } from "bun:sqlite";
import { toCamelCase } from "../../utils/mapper";

export interface Banner {
  id: string;
  name: string;
  type: BannerType;
  mediaUrl: string;
  mediaType: string;
  blurhash: string;
  priority: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export type BannerType = "main" | "menu" | "branch" | "investment" | "career";

export class BannersDatabase {
  private db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  getActive(type: BannerType): Banner[] {
    const results = this.db
      .query(
        `SELECT * FROM banners 
        WHERE is_active = 1
        AND type = ?
        ORDER BY priority ASC
        LIMIT 5`
      )
      .all(type) as object[];
    return results.map(toCamelCase) as Banner[];
  }
}
