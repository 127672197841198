import { Html } from "@elysiajs/html";
import { Button } from "./button";

export default function ContactForm(): JSX.Element {
  return (
    <form
      class="uk-form-stacked bg-white flex-1 p-6 md:py-12 md:px-9 shadow-8px rounded-[20px] text-dark-shade"
      hx-encoding="multipart/form-data"
      hx-post="/contact-us"
      hx-trigger="submit"
      hx-target={`#contact-us`}
      hx-swap="outerHTML"
      id="contact-us"
    >
      <fieldset class="uk-fieldset">
        <div class="mb-3">
          <label class="uk-form-label !text-base" for="name">
            Nama
          </label>
          <div class="uk-form-controls">
            <input
              class="uk-input !h-10 !py-2 !px-3 !rounded-[10px]"
              id="name"
              type="text"
              name="name"
              required
            />
            <p class="uk-form-help uk-text-danger" id="name-error"></p>
          </div>
        </div>
        <div class="mb-3">
          <label class="uk-form-label !text-base" for="email">
            Email
          </label>
          <div class="uk-form-controls">
            <input
              class="uk-input !h-10 !py-2 !px-3 !rounded-[10px]"
              id="email"
              type="email"
              name="email"
              required
            />
            <p class="uk-form-help uk-text-danger" id="email-error"></p>
          </div>
        </div>
        <div class="mb-3">
          <label class="uk-form-label !text-base" for="phone">
            Nomor HP
          </label>
          <div class="uk-form-controls">
            <input
              class="uk-input !h-10 !py-2 !px-3 !rounded-[10px]"
              id="phone"
              type="tel"
              pattern="^(0|62|\+62)(21\d{7,8}|8[1-9]\d{7,10})$"
              name="phone"
              required
            />
            <p class="uk-form-help uk-text-danger" id="phone-error"></p>
          </div>
        </div>
        <div class="mb-3">
          <label class="uk-form-label !text-base" for="category">
            Kategori
          </label>
          <div class="uk-form-controls">
            <select
              class="uk-select !h-10 !py-2 !px-3 !rounded-[10px]"
              id="category"
              name="category"
              required
            >
              <option value="collaboration" selected>
                Kolaborasi
              </option>
              <option value="partnership">Partnership</option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <label class="uk-form-label !text-base" for="message">
            Pesan
          </label>
          <div class="uk-form-controls">
            <textarea
              class="uk-textarea !py-2 !px-3 !min-h-[100px] md:!min-h-[80px] !rounded-[10px]"
              id="message"
              name="message"
              required
            />
            <p class="uk-form-help uk-text-danger" id="message-error"></p>
          </div>
        </div>
        <div class="flex w-full">
          <Button size="md" type="submit" customClass="mx-auto md:mr-0">
            Kirim Form
          </Button>
        </div>
      </fieldset>
    </form>
  );
}
