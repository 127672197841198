export function calculatePercentage(
  currentAmount: number,
  totalMaxAmount: number
): number {
  if (totalMaxAmount === 0) {
    throw new Error("Total maximum amount must not be zero.");
  }

  return (currentAmount / totalMaxAmount) * 100;
}
