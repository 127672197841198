import { decode, encode } from "blurhash";
import sharp, { Sharp } from "sharp";
import { BadRequestError } from "../exceptions";

const blurPixel = 32;

const resizeImage = async (sharpImage: Sharp): Promise<Sharp> => {
  const metadata = await sharpImage.metadata();

  if (!metadata.width) {
    throw new BadRequestError("No metadata width found for image");
  }

  if (!metadata.height) {
    throw new BadRequestError("No metadata height found for image");
  }

  // Resize the image before converting to WebP
  return sharpImage.resize(blurPixel, blurPixel);
};

export const createBlurhash = async (imageArray: Buffer) => {
  const sharpImage = sharp(imageArray);

  const { info } = await sharpImage.ensureAlpha().raw().toBuffer({
    resolveWithObject: true,
  });

  const resizedImage = await resizeImage(sharpImage);
  const { data } = await resizedImage.ensureAlpha().raw().toBuffer({
    resolveWithObject: true,
  });

  const blurhash = encode(
    new Uint8ClampedArray(data),
    blurPixel,
    blurPixel,
    4,
    4
  );

  return `${blurhash}||${info.width}||${info.height}`;
};

export async function generateBlurhashDataUrl(
  blurhash: string,
  width = blurPixel,
  height = blurPixel
): Promise<string> {
  // Decode the blurhash into a pixel array
  const pixels = decode(blurhash, width, height);

  // Convert the pixel array into an image buffer using sharp
  const buffer = await sharp(pixels, {
    raw: {
      width,
      height,
      channels: 4,
    },
  })
    .jpeg()
    .toBuffer();

  // Convert the buffer into a Base64-encoded data URL
  const base64Image = buffer.toString("base64");
  return `data:image/jpeg;base64,${base64Image}`;
}
