import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import BackdropEllipse from "../components/backdrop";
import { Banners, MediaProps } from "../components/banner";
import { ButtonLink } from "../components/buttonLink";
import { Footer } from "../components/footer";
import { Image } from "../components/image";
import { Navbar } from "../components/navbar";
import { Pagination } from "../components/pagination";
import { SearchInput } from "../components/search";
import { Section } from "../components/section";
import { Branch } from "../lib/db/branch";
import { PaginationMetadata } from "../lib/db/root";
import EmptyState from "../components/emptyState";
import { cn } from "../utils/classname";

export type BranchCard = {
  name: string;
  address: string;
  imageUrl: string;
  dataUrl: string;
  weekdayOpeningTime: string;
  weekdayClosingTime: string;
  weekendOpeningTime: string;
  weekendClosingTime: string;
  googleMapsUrl?: string;
};

export type BranchProps = {
  media: MediaProps[];
  children?: JSX.Element;
  branches: BranchCard[];
  metadata: PaginationMetadata;
};

export function BranchCards({
  branches,
  metadata,
}: {
  branches: BranchCard[];
  metadata: PaginationMetadata;
}): JSX.Element {
  if (branches.length <= 0) {
    return (
      <EmptyState
        customClass="mt-4"
        title="Lokasi Belum Tersedia"
        message="Maaf, kami belum hadir di area ini. Coba temukan kedai lain atau periksa kembali nanti."
        icon={
          <img
            src="/public/images/icons/empty-branch.svg"
            alt=""
            width="136"
            height="121"
          />
        }
      />
    );
  }
  return (
    <div class="flex flex-col gap-6">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-6 md:gap-x-6 md:gap-y-[26px] w-full">
        {branches.map((branch) => {
          return (
            <div class="animate-delay-200 animate-in-view-fade w-full h-full py-3 px-6 md:py-[42px] md:px-6 flex flex-col md:flex-row gap-1.5 md:gap-6 rounded-[20px] shadow-8px bg-white">
              <div
                style="aspect-ratio: 1/1;"
                class="w-full min-w-[160px] max-h-[200px] md:max-h-none md:max-w-[215px] mb-auto"
              >
                <Image
                  url={branch.imageUrl}
                  dataUrl={branch.dataUrl}
                  alt=""
                  width={215}
                  height={215}
                  dimensionType="fluid"
                  parentClass="!max-w-none md:!max-w-[215px]"
                  customClass="rounded-[10px] w-full h-full !object-contain md:!object-cover"
                />
              </div>
              <div class="flex flex-col gap-[19px] md:gap-[15px]">
                <h3 class="font-semibold text-xl text-[#3F3F3F]" safe>
                  {branch.name}
                </h3>
                <div class="text-[#676767]">
                  <div class="mb-2.5 flex">
                    <img
                      src="/public/images/icons/map-pin-primary.svg"
                      alt="Alamat"
                      width="18px"
                      height="25px"
                      class="mb-auto"
                    />
                    <p class="ml-3 text-sm" safe>
                      {branch.address}
                    </p>
                  </div>
                  <div class="flex">
                    <img
                      src="/public/images/icons/clock-primary.svg"
                      alt="Jam Buka"
                      width="18px"
                      height="25px"
                      class="mt-1.5 mb-auto"
                    />
                    <p class="ml-3 text-sm">
                      <strong>Mon - Fri</strong>{" "}
                      {branch.weekdayOpeningTime as "safe"} -{" "}
                      {branch.weekdayClosingTime as "safe"}
                      <br />
                      <strong>Sat - Sun</strong>{" "}
                      {branch.weekendOpeningTime as "safe"} -{" "}
                      {branch.weekendClosingTime as "safe"}
                    </p>
                  </div>
                </div>
                {branch.googleMapsUrl ? (
                  <ButtonLink
                    href={branch.googleMapsUrl}
                    size="sm"
                    customClass="mx-auto md:mx-0 md:!font-medium md:!text-base md:!py-3 md:!px-6"
                  >
                    Lihat Lokasi
                  </ButtonLink>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      {metadata.totalPages > 1 && (
        <Pagination
          target="#branch-cards"
          type="branches"
          currentPage={metadata.currentPage}
          totalPages={metadata.totalPages}
        />
      )}
    </div>
  );
}

export function Branch({
  branches,
  media,
  metadata,
}: PropsWithChildren<BranchProps>) {
  return (
    <div class="relative flex flex-col h-full min-h-screen overflow-hidden">
      <div class="fixed inset-0 -z-20">
        <div class="absolute inset-0 backdrop-blur-[1000px]" />
      </div>
      <div class="flex bg-white">
        <Navbar activeRoute="/branches" />
      </div>
      <main
        class={cn("flex-1 flex flex-col pb-10 md:pb-20", {
          "min-h-[500px] lg:min-h-[550px]": !!branches.length && !media.length,
          "min-h-[700px] lg:min-h-[1000px]":
            !!branches.length && !!media.length,
        })}
      >
        {media.length > 0 && (
          <section class="min-h-[200px]">
            <Banners media={media} />
          </section>
        )}
        <Section customClass="flex flex-col sm:px-12 text-primary-foreground py-0 sm:justify-end">
          <SearchInput
            id="search-input"
            name="name"
            hx-get="/branches/filter"
            hx-target="#branch-cards"
            hx-trigger="keyup changed delay:500ms"
            hx-swap="innerHTML"
            hx-boost="true"
            placeholder="Cari Kedai Jus Kode"
            customClass="sm:w-full md:w-[223px]"
            parentClass="flex justify-end"
          />
          <div id="branch-cards">
            <BranchCards branches={branches} metadata={metadata} />
          </div>
        </Section>
      </main>
      <Footer />
      {/* decoration for background */}
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[270px] -right-[250px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[961px] -left-[185px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[2322px] -right-[281px]"
      />
      <BackdropEllipse customClass="hidden md:block top-[2370px] -left-[33px]" />
      <BackdropEllipse customClass="hidden md:block top-[2265px] -right-[148px]" />
      <BackdropEllipse customClass="hidden md:block top-[270px] -right-[317px]" />
      <BackdropEllipse customClass="hidden md:block -top-[280px] -left-[317px]" />
    </div>
  );
}
