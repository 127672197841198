import { Html } from "@elysiajs/html";
import { Image, ImageProps } from "./image";
import { Video, VideoProps } from "./video";

export type MediaProps = {
  type: "image" | "video";
  image?: ImageProps;
  video?: VideoProps;
  title?: string;
  description?: string;
};

export function Banners({
  media: safeMedia,
}: {
  media: MediaProps[];
}): JSX.Element {
  return (
    <div
      class="uk-position-relative uk-visible-toggle"
      tabindex="-1"
      uk-slideshow="max-height: 600; autoplay: true; animation: push;"
    >
      <div class="uk-slideshow-items">
        {safeMedia.map((m) => (
          <Banner media={m} />
        ))}
      </div>
      <ul class="uk-slideshow-nav uk-dotnav uk-flex-center absolute bottom-8 inset-x-0 z-50"></ul>
    </div>
  );
}

export function Banner({ media }: { media: MediaProps }): JSX.Element {
  if (media.type === "image" && media.image) {
    return (
      <div>
        {/* <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1,1.2"> */}
        <Image
          url={media.image.url}
          dataUrl={media.image.dataUrl}
          width={100000}
          dimensionType="fluid"
          alt=""
          uk-cover
          customClass="object-cover w-full h-full"
          loading="eager"
        />
        {/* </div> */}
      </div>
    );
  }

  if (media.type === "video" && media.video) {
    return (
      <div>
        {/* <div class="uk-position-cover" uk-slideshow-parallax="scale: 1.2,1,1.2"> */}
        <Video
          dimensionType="fluid"
          width={100000}
          url={media.video.url}
          uk-cover
          customClass="object-cover w-full h-full"
        />
        {/* </div> */}
      </div>
    );
  }

  return <div />;
}
