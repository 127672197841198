import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../utils/classname";

interface EmptyStateProps {
  customClass?: string;
  icon: JSX.Element;
  title?: string;
  message?: string;
}

const EmptyState = ({
  icon,
  title = "Belum Ada Data",
  message = "Saat ini data masih kosong. Silakan tambahkan data baru untuk memulai",
  customClass = "",
}: PropsWithChildren<EmptyStateProps>): JSX.Element => {
  return (
    <div class={cn("flex flex-col gap-3 items-center mx-auto text-center", customClass)}>
      {icon}
      <h4 class="text-xl font-semibold text-dark-grey" safe>
        {title}
      </h4>
      <p class="text-grey" safe>
        {message}
      </p>
    </div>
  );
};

export default EmptyState;
