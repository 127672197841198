import { Html } from "@elysiajs/html";
import { cn } from "../utils/classname";

export type ImageProps = {
  url: string;
  dataUrl: string;
  alt: string;
  width: number;
  height?: number;
  loading?: string;
  customClass?: string;
  parentClass?: string;
  dimensionType?: "fixed" | "fluid";
};

/* Disable srcset for a while due to limitted resources */

const constructSrcSet = (url: string) => {
  const srcSet = [];
  const urlParts = url.split(".");
  const extension = urlParts.pop();
  const urlWithoutExtension = urlParts.join(".");
  const sizes = [640];
  sizes.forEach((size) => {
    srcSet.push(`${urlWithoutExtension}-${size}w.${extension} ${size}w`);
  });
  srcSet.push(`${urlWithoutExtension}.${extension} 1440w`);
  return srcSet.join(", ");
};

export function Image({
  url,
  dataUrl,
  alt,
  width,
  height,
  loading = "lazy",
  customClass = "",
  parentClass = "",
  dimensionType = "fixed",
}: ImageProps): JSX.Element {
  // Generate unique IDs for targeting the elements
  const uniqueId = Math.random().toString(36).substring(2, 9);
  const blurImgId = `blur-img-${uniqueId}`;
  const realImgId = `real-img-${uniqueId}`;
  let srcSet = width > 400 ? constructSrcSet(url) : undefined;

  return (
    <div
      class={cn("relative", parentClass)}
      style={{
        maxWidth: `${width}px`, // Sets the maximum width based on the provided width prop
        width: "100%",
        height: dimensionType === "fluid" ? "100%" : `${height}px`, // Set the height explicitly based on the provided height prop
        overflow: "hidden",
        ...(dimensionType === "fluid" && {
          maxHeight: `${height}px`,
          aspectRatio: `${width}/${height}`,
        }), // Maintain aspect ratio
      }}
    >
      {/* Blurred Placeholder Image */}
      <img
        id={blurImgId}
        src={dataUrl}
        alt={alt}
        class={cn(
          "absolute inset-0 object-cover w-full transition-opacity duration-300 blur-placeholder",
          customClass
        )}
        loading={loading}
      />

      {/* Real Image */}
      {srcSet ? (
        <img
          id={realImgId}
          src={url}
          srcset={srcSet}
          alt={alt}
          class={cn(
            "absolute inset-0 object-cover w-full opacity-0 transition-opacity duration-300 real-image",
            customClass
          )}
          data-blur-id={blurImgId}
          loading={loading}
        />
      ) : (
        <img
          id={realImgId}
          src={url}
          alt={alt}
          class={cn(
            "absolute inset-0 object-cover w-full opacity-0 transition-opacity duration-300 real-image",
            customClass
          )}
          data-blur-id={blurImgId}
          loading={loading}
        />
      )}
    </div>
  );
}
