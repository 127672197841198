type CamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${T}${Capitalize<CamelCase<U>>}`
  : S;

type CamelCasedProperties<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K];
};

// Utility function to convert snake_case to camelCase
export const toCamelCase = <T extends object>(
  obj: T
): CamelCasedProperties<T> => {
  const newObj: any = {};
  for (const key in obj) {
    const camelCaseKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    newObj[camelCaseKey] = obj[key];
  }
  return newObj as CamelCasedProperties<T>;
};

export const constructPaginationMetadata = (
  totalResults: { total: number },
  page: number,
  pageSize: number
) => {
  const totalData = totalResults.total;
  const totalPages = Math.ceil(totalData / pageSize);
  const currentPage = page;
  const prevPage = currentPage > 0 ? currentPage - 1 : null;
  const nextPage = currentPage < totalPages - 1 ? currentPage + 1 : null;

  return {
    currentPage,
    prevPage,
    nextPage,
    totalData,
    totalPages,
  };
};
