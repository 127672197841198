import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { MediaProps } from "../components/banner";
import { Head } from "../components/head";
import { databaseModels } from "../lib/db";
import { Banner } from "../lib/db/banner";
import { Career, CareerAccordions } from "../pages/career";
import { generateBlurhashDataUrl } from "../utils/blurhash";
import { searchValidation } from "../validation/search";

const constructMedia = async (banners: Banner[]) => {
  const media: MediaProps[] = [];

  for (const banner of banners) {
    if (banner.mediaType === "image") {
      const dataUrl = await generateBlurhashDataUrl(banner.blurhash, 32, 32);
      media.push({
        type: "image",
        image: {
          url: banner.mediaUrl,
          dataUrl,
          alt: banner.name,
          width: 1440,
        },
      });
    } else {
      media.push({
        type: "video",
        video: {
          url: banner.mediaUrl,
          width: 1280,
          height: 720,
        },
      });
    }
  }

  return media;
};

export const careerController = new Elysia().group("/careers", (app) =>
  app
    .decorate("db", databaseModels)
    .get("/", async ({ db }): Promise<JSX.Element> => {
      const title = "Jus Kode - Karir";

      const careers = db.careers.getList();
      const banners = db.banners.getActive("career");
      const media = await constructMedia(banners);

      return (
        <Head title={title}>
          <Career media={media} careers={careers} />
        </Head>
      );
    })
    .get(
      "/filter",
      ({ db, query }): JSX.Element => {
        const name = query.name;

        if (!name || name.length < 3) {
          const careers = db.careers.getList();
          return <CareerAccordions careers={careers} />;
        }

        const careers = db.careers.search(name);
        return <CareerAccordions careers={careers} />;
      },
      { ...searchValidation }
    )
);
