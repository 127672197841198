import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { MediaProps } from "../components/banner";
import { Head } from "../components/head";
import { ImageProps } from "../components/image";
import { databaseModels } from "../lib/db";
import { Investment } from "../lib/db/investment";
import { Invest, InvestmentCard } from "../pages/invest";
import { generateBlurhashDataUrl } from "../utils/blurhash";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";

const constructInvestmentCard = async (
  investments: Investment[]
): Promise<InvestmentCard[]> => {
  const investmentCards: InvestmentCard[] = [];
  for (const investment of investments) {
    const images: { type: "image"; image: ImageProps }[] = [];
    for (const image of investment.images) {
      const dataUrl = await generateBlurhashDataUrl(image.blurhash, 32, 32);
      images.push({
        type: "image",
        image: {
          url: image.imageUrl,
          dataUrl,
          alt: investment.branchName,
          width: 1440,
        },
      });
    }

    investmentCards.push({
      ...investment,
      images,
    });
  }

  return investmentCards;
};

export const investController = new Elysia().group("/investments", (app) =>
  app
    .decorate("db", databaseModels)
    .get("/", async ({ db }): Promise<JSX.Element> => {
      const title = "Jus Kode - Investasi";

      const banners = db.banners.getActive("investment");
      const media: MediaProps[] = [];

      for (const banner of banners) {
        if (banner.mediaType === "image") {
          const dataUrl = await generateBlurhashDataUrl(
            banner.blurhash,
            32,
            32
          );
          media.push({
            type: "image",
            image: {
              url: banner.mediaUrl,
              dataUrl,
              alt: banner.name,
              width: 1440,
            },
          });
        } else {
          media.push({
            type: "video",
            video: {
              url: banner.mediaUrl,
              width: 1280,
              height: 720,
            },
          });
        }
      }

      const { data, metadata: _ } = db.investments.getPaginated(
        DEFAULT_CURRENT_PAGE,
        DEFAULT_PAGE_SIZE
      );

      const investmentCards: InvestmentCard[] = await constructInvestmentCard(
        data
      );

      return (
        <Head title={title}>
          <Invest media={media} investments={investmentCards} />
        </Head>
      );
    })
);
