import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { MediaProps } from "../components/banner";
import { Head } from "../components/head";
import { databaseModels } from "../lib/db";
import { Menu, ProductCard } from "../pages/menu";
import { ProductMedia, ProductService } from "../services/product";
import { generateBlurhashDataUrl } from "../utils/blurhash";
import { getProductsValidation } from "../validation/product";

const getCards = (products: ProductMedia[]): JSX.Element => {
  return <ProductCard products={products} />;
};

export const menuController = new Elysia().group("/menus", (app) =>
  app
    .decorate("db", databaseModels)
    .get("/", async ({ db }): Promise<JSX.Element> => {
      const title = "Jus Kode - Menu";

      const banners = db.banners.getActive("menu");
      const media: MediaProps[] = [];

      for (const banner of banners) {
        if (banner.mediaType === "image") {
          const dataUrl = await generateBlurhashDataUrl(
            banner.blurhash,
            32,
            32
          );
          media.push({
            type: "image",
            image: {
              url: banner.mediaUrl,
              dataUrl,
              alt: banner.name,
              width: 1440,
            },
          });
        } else {
          media.push({
            type: "video",
            video: {
              url: banner.mediaUrl,
              width: 1280,
              height: 720,
            },
          });
        }
      }

      const featuredProducts = await ProductService.getFeatured(db);
      const juicesProducts = await ProductService.getJuices(db);
      const dessertsProducts = await ProductService.getDesserts(db);

      return (
        <Head title={title}>
          <Menu
            media={media}
            featuredProducts={featuredProducts}
            juicesProducts={juicesProducts}
            dessertsProducts={dessertsProducts}
          ></Menu>
        </Head>
      );
    })
    .get(
      "/cards",
      async ({ db, query, set }): Promise<JSX.Element> => {
        const category = query.category as "dessert" | "food";
        let products: ProductMedia[] = [];

        if (category === "dessert") {
          products = await ProductService.getDesserts(db);
        } else if (category === "food") {
          products = await ProductService.getFoods(db);
        }

        set.headers["cache-control"] = "no-store, max-age=0";
        return getCards(products);
      },
      { ...getProductsValidation }
    )
);
