import { Html } from "@elysiajs/html";
import { cn } from "../utils/classname";

export type VideoProps = {
  url: string;
  width: number;
  height?: number;
  customClass?: string;
  dimensionType?: "fixed" | "fluid";
};

export function Video({
  url,
  width,
  height,
  customClass = "",
  dimensionType = "fixed",
}: VideoProps): JSX.Element {
  // Generate unique IDs for targeting the elements
  const uniqueId = Math.random().toString(36).substring(2, 9);

  return (
    <div
      class="relative"
      style={{
        maxWidth: `${width}px`, // Sets the maximum width based on the provided width prop
        width: "100%",
        height: dimensionType === "fluid" ? "100%" : `${height}px`, // Set the height explicitly based on the provided height prop
        overflow: "hidden",
        ...(dimensionType === "fluid" && {
          maxHeight: `${height}px`,
          aspectRatio: `${width}/${height}`,
        }), // Maintain aspect ratio
      }}
    >
      {/* Video Element */}
      <video
        id={`video-${uniqueId}`}
        loop
        playsinline
        muted
        autoplay
        controls
        class={cn("absolute inset-0 object-cover w-full h-full", customClass)}
      >
        <source src={url} type="video/mp4" />
      </video>
      {/* Fallback for unsupported browsers */}
      {/* <div class="absolute inset-0 flex items-center justify-center bg-gray-800 text-white">
            <p>
              Your browser does not support the video tag. Please update your
              browser or try a different one.
            </p>
          </div> */}
    </div>
  );
}
