import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { NotFoundPage } from "../pages/notfound";

export const notFoundController = new Elysia().get(
  "/not-found",
  async ({}): Promise<JSX.Element> => {
    const title = "Jus Kode | Halaman tidak ditemukan";
    return <NotFoundPage title={title} />;
  }
);
