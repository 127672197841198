import { Html } from "@elysiajs/html";
import BackdropEllipse from "../components/backdrop";
import { Banners, MediaProps } from "../components/banner";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { SearchInput } from "../components/search";
import { Section } from "../components/section";
import { Career } from "../lib/db/career";
import ChevronDown from "../components/icons/chevronDown";
import EmptyState from "../components/emptyState";
import { cn } from "../utils/classname";

export function CareerAccordions({
  careers,
}: {
  careers: Career[];
}): JSX.Element {
  if (careers.length <= 0) {
    return (
      <EmptyState
        customClass="mt-4"
        title="Lowongan Kerja Belum Tersedia"
        message="Saat ini belum ada lowongan kerja yang tersedia.  Kembali nanti untuk peluang karier di Jus Kode!"
        icon={
          <img
            src="/public/images/icons/empty-file.svg"
            alt=""
            width="136"
            height="121"
          />
        }
      />
    );
  }

  return (
    <>
      <div class="hidden md:!flex gap-3 p-3 pl-[60px] text-primary-foreground text-[28px] leading-9 font-bold mb-6">
        <h2 class="flex-1">Posisi</h2>
        <h2 class="flex-1">Divisi</h2>
        <h2 class="flex-1">Lokasi</h2>
      </div>
      <ul class="uk-accordion" uk-accordion>
        {careers.map((career, index) => (
          <li
            class={`shadow-6px !border-0 rounded-[20px] p-3 bg-white ${
              index !== 0 ? "!mt-6" : ""
            }`}
          >
            <a
              class="uk-accordion-title !relative !block md:!flex md:gap-3 !py-1.5 !px-2 md:!pl-[48px] md:!pr-0 items-center text-base md:text-lg !text-dark-shade hover:!no-underline"
              href=""
            >
              <span class="uk-accordion-icon absolute left-0 top-0 hidden md:!flex items-center justify-center w-[36px] h-[40px]">
                <ChevronDown />
              </span>
              <span class="flex-1 mb-1 md:mb-0 font-medium md:font-normal" safe>
                {career.name}
              </span>
              <br class="md:hidden" />
              <span
                class="md:flex-1 font-normal border-r border-dark-shade mr-2 pr-2 md:border-0 md:mr-0 md:pr-0"
                safe
              >
                {career.division}
              </span>
              <span class="md:flex-1 font-normal" safe>
                {career.location}
              </span>
            </a>
            <div class="uk-accordion-content mt-1.5 md:mt-1 px-2 md:pl-[48px] md:pr-0 !pb-0 !text-dark-shade">
              <div class="h-[40px] flex items-center mb-1.5 md:mb-1 border-t border-[#4D4D4D]">
                <span class="text-xl font-semibold">Persyaratan:</span>
              </div>
              <div class="py-0 md:py-2 mb-1.5 md:mb-1 use-default break-words">
                {career.requirements as "safe"}
              </div>
              <div class="py-[13px] md:py-2 text-base border-t border-[#4D4D4D]">
                <p>
                  Kirim CV Anda ke email <strong>{career.email}</strong> dengan
                  subjek email <strong>“Posisi – [Nama Pendaftar]”</strong>
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}

export function Career({
  media,
  careers,
}: {
  careers: Career[];
  media: MediaProps[];
}): JSX.Element {
  return (
    <div class="relative flex flex-col h-full min-h-screen overflow-hidden">
      <div class="fixed inset-0 -z-20">
        <div class="absolute inset-0 backdrop-blur-[1000px]" />
      </div>
      <div class="flex bg-white">
        <Navbar activeRoute="/careers" />
      </div>
      <main
        class={cn("flex-1 flex flex-col pb-10 md:pb-20", {
          "min-h-[500px] lg:min-h-[550px]": !!careers.length && !media.length,
          "min-h-[700px] lg:min-h-[1000px]": !!careers.length && !!media.length,
        })}
      >
        {media.length > 0 && (
          <section class="min-h-[200px]">
            <Banners media={media} />
          </section>
        )}
        <Section customClass="flex flex-col sm:px-12 text-primary-foreground py-0">
          <SearchInput
            name="name"
            hx-get="/careers/filter"
            hx-target="#career-accordions"
            hx-trigger="keyup changed delay:500ms"
            hx-swap="innerHTML"
            hx-boost="true"
            placeholder="Cari Lowongan Kerja"
            customClass="sm:w-full md:w-[223px]"
            parentClass="flex justify-end"
          />
          <div id="career-accordions">
            <CareerAccordions careers={careers} />
          </div>
        </Section>
      </main>
      <Footer />
      {/* decoration for background */}
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[270px] -right-[250px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[961px] -left-[185px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[2322px] -right-[281px]"
      />
      <BackdropEllipse customClass="hidden md:block top-[2370px] -left-[33px]" />
      <BackdropEllipse customClass="hidden md:block top-[2265px] -right-[148px]" />
      <BackdropEllipse customClass="hidden md:block top-[270px] -right-[317px]" />
      <BackdropEllipse customClass="hidden md:block -top-[280px] -left-[317px]" />
    </div>
  );
}
