import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../utils/classname";
import SearchIcon from "./icons/search";

interface SearchProps extends JSX.HtmlInputTag {
  customClass?: string;
  parentClass?: string;
}

export function SearchInput({
  customClass = "",
  parentClass = "",
  ...props
}: PropsWithChildren<SearchProps>): JSX.Element {
  return (
    <div class={cn("flex-1 uk-inline w-full mb-6", parentClass)}>
      <div class="absolute inset-y-0 left-[20px] md:left-auto md:right-[185px] flex items-center">
        <SearchIcon />
      </div>
      <input
        {...props}
        class={cn(
          "uk-input bg-white rounded-[20px] py-[14px] pr-[20px] pl-[54px] text-sm font-medium h-[48px] border-none text-[#251F1F] placeholder:text-dark-grey shadow-6px",
          customClass
        )}
      />
    </div>
  );
}
