import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import BackdropEllipse from "../components/backdrop";
import { Head } from "../components/head";

export function NotFoundPage({
  title,
}: PropsWithChildren<{
  title: string;
  children?: JSX.Element;
}>) {
  return (
    <Head title={title}>
      <div class="relative flex flex-col min-h-screen overflow-hidden">
        <div class="fixed inset-0 -z-20">
          <div class="absolute inset-0 backdrop-blur-[1000px]" />
        </div>

        <main class="flex-1 flex justify-center items-center">
          <div class="uk-card h-fit py-12 w-full sm:w-auto sm:min-w-[392px] max-w-[392px] bg-white rounded-[20px] shadow-6px !border-0">
            <div class="uk-card-body text-center">
              <h1 class="text-3xl font-semibold text-primary">404</h1>
              <p class="text-lg font-medium text-gray-500">
                Oops! The page you are looking for does not exist. It might have
                been removed or its name changed.
              </p>
              <a
                href={"/"}
                class="mt-6 inline-block px-6 py-3 bg-primary text-white font-medium rounded-[10px] transition-colors duration-200 hover:bg-primary-dark"
              >
                Go back to home
              </a>
            </div>
          </div>
        </main>
        {/* Convert static divs to BackdropEllipse components */}
        <BackdropEllipse customClass="-top-[280px] -left-[317px]" />
        <BackdropEllipse customClass="top-[270px] -right-[317px]" />
      </div>
    </Head>
  );
}
