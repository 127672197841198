import { Html } from "@elysiajs/html";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { Section } from "../components/section";
import BackdropEllipse from "../components/backdrop";
import ContactForm from "../components/contactForm";
import { config } from "../config";

export function Contact() {
  return (
    <div class="relative flex flex-col h-full min-h-screen overflow-hidden">
      <div class="fixed inset-0 -z-20">
        <div class="absolute inset-0 backdrop-blur-[1000px]" />
      </div>
      <div class="flex bg-white">
        <Navbar activeRoute="/contact-us" />
      </div>

      <main class="flex-1 flex flex-col md:pt-[60px] md:pb-[78px]">
        <Section customClass="flex flex-col lg:flex-row gap-6 md:gap-[26px] !mt-0 !py-12 md:!py-[60px]">
          <div class="flex-1 flex flex-col items-center md:items-start gap-4 md:gap-[26px]">
            <h2 class="text-primary-foreground font-bold md:font-semibold text-[28px] leading-9 md:text-4xl md:leading-9">
              Hubungi Kami
            </h2>
            <p class="text-grey text-center md:text-left text-sm md:text-base">
              Jika Anda memiliki pertanyaan atau membutuhkan informasi lebih
              lanjut, jangan ragu untuk menghubungi kami. Kami siap membantu
              Anda!
            </p>
            <div class="flex items-center gap-4">
              <a
                href={config.user.instagram}
                class="w-8 h-8 rounded-full flex justify-center items-center bg-primary"
              >
                <img
                  src="/public/images/icons/instagram.svg"
                  alt="Instagram"
                  width="18px"
                  height="18px"
                />
              </a>
              <a
                href={config.user.whatsappUrl}
                class="w-8 h-8 rounded-full flex justify-center items-center bg-primary"
              >
                <img
                  src="/public/images/icons/whatsapp.svg"
                  alt="WhatsApp"
                  width="18px"
                  height="18px"
                />
              </a>
              <a
                href={config.user.feedbackForm}
                class="w-8 h-8 rounded-full flex justify-center items-center bg-primary"
              >
                <img
                  src="/public/images/icons/feedback.svg"
                  alt="Feedback"
                  width="16px"
                  height="16px"
                />
              </a>
            </div>
          </div>
          <ContactForm />
        </Section>
      </main>
      <Footer />
      {/* decoration for background */}
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[270px] -right-[250px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[961px] -left-[185px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[2322px] -right-[281px]"
      />
      <BackdropEllipse customClass="hidden md:block top-[2370px] -left-[33px]" />
      <BackdropEllipse customClass="hidden md:block top-[2265px] -right-[148px]" />
      <BackdropEllipse customClass="hidden md:block top-[270px] -right-[317px]" />
      <BackdropEllipse customClass="hidden md:block -top-[280px] -left-[317px]" />
    </div>
  );
}
