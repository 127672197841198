import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";

interface ProgressProps {
  value: number;
  min?: number;
  max?: number;
}

export function Progress({
  value,
  min = 0,
  max = 100,
}: PropsWithChildren<ProgressProps>): JSX.Element {
  return (
    <div
      class="w-full h-4 overflow-hidden rounded-[15px] bg-[#A7A995]"
      role="progressbar"
      aria-valuenow={value.toString()}
      aria-valuemin={min.toString()}
      aria-valuemax={max.toString()}
    >
      <div
        class="h-full rounded-l-[15px] bg-gradient-to-r from-[#7B9400] to-[#2B2E18]"
        style={`width: ${value}%;`}
      />
      <progress hidden value={value.toString()} max={max.toString()}></progress>
    </div>
  );
}
