import RedisClient from "ioredis";
import { Logger } from "../lib/logger";
import { redisConfig } from "../config/redis";
import { Environment } from "../utils/constants";

const redisClientSingleton = () => {
  return new RedisClient(redisConfig).on("error", (error) => {
    Logger.error("Redis error: " + error.message);
  });
};

type RedisSingleton = ReturnType<typeof redisClientSingleton>;

const global = globalThis as unknown as {
  redis: RedisSingleton;
};

export const redisClient = global.redis ?? redisClientSingleton();

if (process.env.NODE_ENV !== Environment.PRODUCTION.toString())
  global.redis = redisClient;
