import { t } from "elysia";

export const getProductsValidation = {
  query: t.Object({
    category: t.Enum(
      { juice: "juice", dessert: "dessert", food: "food" },
      {
        error:
          "Oh no! Category must be one of 'juice', 'dessert', or 'food', pretty please.",
      }
    ),
    page: t.Optional(
      t.Number({ error: "Oopsie! Page must be a number, please." })
    ),
    pageSize: t.Optional(
      t.Number({ error: "Oh no! Page size must be a number, pretty please." })
    ),
  }),
};
