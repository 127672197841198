import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { cn } from "../utils/classname";

interface SectionProps {
  children: JSX.Element | JSX.Element[] | string;
  customClass?: string;
}

export function Section({
  children: safeChildren,
  customClass,
}: PropsWithChildren<SectionProps>): JSX.Element {
  return (
    <section
      class={cn(
        "max-w-[1440px] mx-auto w-full mt-10 md:mt-[60px] py-6 px-6 md:px-[72px] xl:px-36",
        customClass
      )}
    >
      {safeChildren}
    </section>
  );
}
