import { Html } from "@elysiajs/html";
import { type PropsWithChildren } from "@kitajs/html";
import { Banners, MediaProps } from "../components/banner";
import { ButtonLink } from "../components/buttonLink";
import { Footer } from "../components/footer";
import { Image } from "../components/image";
import { Navbar } from "../components/navbar";
import { Section } from "../components/section";
import { ProductMedia } from "../services/product";
import { formatToRupiah } from "../utils/string";
import BackdropEllipse from "../components/backdrop";
import EmptyState from "../components/emptyState";

export type HomeProps = {
  children?: JSX.Element;
  media: MediaProps[];
  products: ProductMedia[];
};

export function Home({ products, media }: PropsWithChildren<HomeProps>) {
  return (
    <div class="relative flex flex-col h-full min-h-screen overflow-hidden">
      <div class="fixed inset-0 -z-20">
        <div class="absolute inset-0" />
      </div>
      <div class="flex bg-white">
        <Navbar activeRoute="/" />
      </div>

      <main class="relative flex-1 flex flex-col pb-10 md:pb-[105px] min-h-[2000px]">
        {media.length > 0 && (
          <section class="min-h-[200px]">
            <Banners media={media} />
          </section>
        )}
        <Section customClass="flex flex-col lg:flex-row justify-center gap-6 text-center">
          <div class="animate-in-view-scale-up  animate-delay-200 bg-white flex flex-col p-6 md:pb-10 lg:pb-6 pt-[28px] lg:pt-14 items-center gap-1.5 md:gap-6 w-full lg:w-[367px] h-fit lg:h-[363px] rounded-[20px] shadow-6px lg:shadow-8px">
            <img
              src="/public/images/icons/verified-primary.svg"
              alt=""
              width="55"
              height="53"
              style="aspect-ratio: 55/53;"
              class="w-[55px] h-[53px] md:w-[88px] md:h-[84px]"
            />
            <h3 class="font-bold text-xl md:text-[28px] md:leading-9 text-primary-foreground">
              Harga Terjangkau
            </h3>
            <p class="text-sm md:text-lg text-neutral-600">
              Jus segar berkualitas dengan harga ramah di kantong!
            </p>
          </div>
          <div class="animate-in-view-scale-up animate-delay-400 bg-white flex flex-col p-6 md:pb-10 lg:pb-6 pt-[34px] lg:pt-[76px] items-center gap-1.5 md:gap-6 w-full lg:w-[367px] h-fit lg:h-[363px] rounded-[20px] shadow-6px lg:shadow-8px">
            <img
              src="/public/images/icons/leaf-primary.svg"
              alt=""
              width="40"
              height="40"
              style="aspect-ratio: 40/40;"
              class="w-[40px] h-[40px] md:w-[64px] md:h-[64px]"
            />
            <h3 class="font-bold text-xl md:text-[28px] md:leading-9 text-primary-foreground">
              Segar Setiap Hari
            </h3>
            <p class="text-sm md:text-lg text-neutral-600">
              Jus kami dibuat dari buah segar terbaik di setiap gelasnya.
            </p>
          </div>
          <div class="animate-in-view-scale-up  animate-delay-600 bg-white flex flex-col p-6 md:pb-10 lg:pb-6 pt-[30px] lg:pt-[64px] items-center gap-1.5 md:gap-6 w-full lg:w-[367px] h-fit lg:h-[363px] rounded-[20px] shadow-6px lg:shadow-8px">
            <img
              src="/public/images/icons/cart-primary.svg"
              alt=""
              width="55"
              height="48"
              style="aspect-ratio: 55/48;"
              class="w-[55px] h-[48px] md:w-[88px] md:h-[76px]"
            />
            <h3 class="font-bold text-xl md:text-[28px] md:leading-9 text-primary-foreground">
              Varian Lengkap
            </h3>
            <p class="text-sm md:text-lg text-neutral-600">
              Pilih dari berbagai varian rasa yang lengkap, sesuai selera Anda!
            </p>
          </div>
        </Section>
        <Section customClass="flex flex-col lg:flex-row gap-12 text-primary-foreground justify-between">
          <div class="flex min-w-[240px] my-auto mx-auto lg:mx-0">
            <h2 class="font-semibold text-[28px] leading-9 md:text-4xl md:leading-10">
              Sekilas
              <br class="hidden lg:!block" /> Tentang Kami
            </h2>
          </div>
          <div class="flex flex-col gap-1.5 md:gap-16 max-w-[753px] px-6 md:px-0">
            <div class="animate-in-view-slide-left-medium  animate-delay-200 flex flex-col md:flex-row gap-1.5 md:gap-[43px] items-center md:items-start">
              <div class="bg-white shrink-0 w-[60px] h-[60px] md:w-[75px] md:h-[75px] rounded-[10px] md:rounded-[20px] shadow-8px flex">
                <img
                  src="/public/images/icons/glass-primary.svg"
                  alt=""
                  width="30px"
                  height="34px"
                  class="m-auto w-[30px] h-[34px] md:w-[38px] md:h-[42px]"
                />
              </div>
              <div class="text-center md:text-left">
                <h3 class="text-xl md:text-[28px] md:leading-9 font-bold mb-1.5 md:mb-2">
                  Pelopor Jus Jumbo Indonesia
                </h3>
                <p class="text-sm md:text-lg text-dark-grey">
                  Ciri khas kami adalah jus berukuran besar dengan harga
                  terjangkau, menawarkan kesegaran maksimal untuk semua.
                </p>
              </div>
            </div>
            <div class="animate-in-view-slide-left-medium  animate-delay-400 flex flex-col md:flex-row gap-1.5 md:gap-[43px] items-center md:items-start">
              <div class="bg-white shrink-0 w-[60px] h-[60px] md:w-[75px] md:h-[75px] rounded-[10px] md:rounded-[20px] shadow-8px flex">
                <img
                  src="/public/images/icons/healthy-primary.svg"
                  alt=""
                  width="29px"
                  height="24px"
                  class="m-auto w-[29px] h-[24px] md:w-[36px] md:h-[30px]"
                />
              </div>
              <div class="text-center md:text-left">
                <h3 class="text-xl md:text-[28px] md:leading-9 font-bold mb-1.5 md:mb-2">
                  Gaya Hidup Sehat
                </h3>
                <p class="text-sm md:text-lg text-dark-grey">
                  Kami berdedikasi untuk mendukung gaya hidup sehat dengan
                  inovasi jus yang kaya vitamin, mineral, dan antioksidan.
                </p>
              </div>
            </div>
          </div>
        </Section>
        <Section customClass="flex flex-col sm:px-12 gap-6 md:gap-10 items-center text-primary-foreground">
          <h2 class="font-semibold text-[28px] leading-9 md:text-4xl md:leading-10">
            Menu Baru
          </h2>
          {products.length > 0 ? (
            <>
              <div class="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-6 w-full">
                {products.map((product) => (
                  <div
                    class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 relative group w-full"
                    style="aspect-ratio: 1/1;"
                  >
                    <Image
                      url={product.image.url}
                      dataUrl={product.image.dataUrl}
                      alt={`Gambar ${product.name}`}
                      width={368}
                      height={368}
                      dimensionType="fluid"
                      customClass="rounded-[20px] w-full"
                    />
                    <div class="uk-overlay uk-position-cover hidden group-hover:block bg-black opacity-80 rounded-[20px]" />
                    <div class="uk-overlay uk-position-cover hidden group-hover:flex flex-col text-center justify-center text-white rounded-[20px]">
                      <h3
                        class="font-bold text-lg lg:text-[28px] lg:leading-9"
                        safe
                      >
                        {product.name.replace("(", "\n(")}
                      </h3>
                      {product.category === "juice" &&
                      product.secondaryPrice > 0 ? (
                        <>
                          <span class="text-sm lg:text-2xl" safe>
                            (K) {formatToRupiah(product.price)}
                          </span>
                          <span class="text-sm lg:text-2xl" safe>
                            (B) {formatToRupiah(product.secondaryPrice)}
                          </span>
                        </>
                      ) : (
                        <span class="text-sm lg:text-2xl" safe>
                          {formatToRupiah(product.price)}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <ButtonLink href="/menus" customClass="w-[250px] md:w-fit">
                Selengkapnya
              </ButtonLink>
            </>
          ) : (
            <EmptyState
              customClass="mt-10"
              title="Produk Belum Tersedia"
              message="Saat ini belum ada produk di kategori ini. Silakan jelajahi produk kami lainnya!"
              icon={
                <img
                  src="/public/images/icons/empty-product.svg"
                  alt=""
                  width="136"
                  height="121"
                />
              }
            />
          )}
        </Section>
        <Section customClass="flex flex-col lg:flex-row md:items-center lg:items-start gap-3 md:gap-12 lg:gap-6 xl:gap-12 z-10">
          <div class="flex flex-col px-[23px] gap-3 my-auto text-center md:text-left">
            <h1 class="font-semibold text-[28px] leading-9 md:text-[64px] md:leading-[76px] text-primary-foreground">
              Nikmati Jus Segar Dekat Anda!
            </h1>
            <p class="text-base md:text-lg text-[#676767]">
              Kunjungi kedai kami untuk menikmati jus jumbo dengan varian rasa
              yang beragam. Dengan lebih dari 60 cabang, kami hadir di berbagai
              kota untuk memudahkan Anda menikmati kesegaran jus alami setiap
              hari.
            </p>
            <ButtonLink href="/branches" customClass="hidden md:!block">
              Lihat Kedai
            </ButtonLink>
          </div>
          <div class="shrink-0 mx-auto md:mx-0">
            <img
              src="/public/images/store.avif"
              alt="Ilustrasi Kedai Jus Kode"
              width="435px"
              height="559px"
            />
          </div>
          <ButtonLink
            href="/branches"
            customClass="block md:!hidden w-[250px] mx-auto"
          >
            Lihat Kedai
          </ButtonLink>
        </Section>
        <Section customClass="md:!flex flex-col items-center text-center text-primary-foreground">
          <span class="text-[28px] leading-9 font-bold mb-3">
            Segera Hadir!
          </span>
          <h2 class="font-black text-4xl mb-6">Jus Kode App</h2>
          <p class="text-sm text-grey mb-8 max-w-[447px]">
            Pesan jus segar hanya dengan beberapa ketukan. Dapatkan produk kami
            kapan saja dan di mana saja!
          </p>
          <div class="flex flex-col md:flex-row gap-5 items-center md:items-start">
            <a href="#" class="cursor-default">
              <img
                src="/public/images/app-store.avif"
                alt="App Store"
                width="154px"
                height="61px"
                loading="lazy"
              />
            </a>
            <a href="#" class="cursor-default">
              <img
                src="/public/images/google-play.avif"
                alt="Google Play"
                width="154px"
                height="61px"
                loading="lazy"
              />
            </a>
          </div>
        </Section>
        <img
          class="absolute bottom-0 z-0 hidden md:block w-full"
          src="/public/images/background.webp"
          alt="background"
          loading="lazy"
        />
      </main>
      <Footer />
      {/* decoration for background */}
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[270px] -right-[250px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[961px] -left-[185px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[2322px] -right-[281px]"
      />
      <BackdropEllipse customClass="hidden md:block top-[2939px] -left-[241px]" />
      <BackdropEllipse customClass="hidden md:block top-[2322px] -right-[348px]" />
      <BackdropEllipse customClass="hidden md:block top-[961px] -left-[185px]" />
      <BackdropEllipse customClass="hidden md:block top-[270px] -right-[317px]" />
    </div>
  );
}
