import { Html } from "@elysiajs/html";
import Elysia from "elysia";
import { MediaProps } from "../components/banner";
import { Head } from "../components/head";
import { DatabaseModels, databaseModels } from "../lib/db";
import { Branch, BranchCard, BranchCards } from "../pages/branch";
import { generateBlurhashDataUrl } from "../utils/blurhash";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "../utils/constants";
import { searchValidation } from "../validation/search";

const getPaginated = async (
  db: DatabaseModels,
  page: number = DEFAULT_CURRENT_PAGE,
  pageSize: number = DEFAULT_PAGE_SIZE,
  name?: string
): Promise<JSX.Element> => {
  const { data, metadata } = db.branches.getPaginated(page, pageSize, name);
  const branchCards: BranchCard[] = [];

  for (const branch of data) {
    const dataUrl = await generateBlurhashDataUrl(branch.blurhash, 32, 32);
    branchCards.push({
      ...branch,
      dataUrl,
    });
  }

  return <BranchCards branches={branchCards} metadata={metadata} />;
};

export const branchController = new Elysia().group("/branches", (app) =>
  app
    .decorate("db", databaseModels)
    .get("/", async ({ db }): Promise<JSX.Element> => {
      const title = "Jus Kode - Lokasi Cabang";

      const banners = db.banners.getActive("branch");
      const media: MediaProps[] = [];

      for (const banner of banners) {
        if (banner.mediaType === "image") {
          const dataUrl = await generateBlurhashDataUrl(
            banner.blurhash,
            32,
            32
          );
          media.push({
            type: "image",
            image: {
              url: banner.mediaUrl,
              dataUrl,
              alt: banner.name,
              width: 1440,
            },
          });
        } else {
          media.push({
            type: "video",
            video: {
              url: banner.mediaUrl,
              width: 1280,
              height: 720,
            },
          });
        }
      }

      const { data, metadata } = db.branches.getPaginated(
        DEFAULT_CURRENT_PAGE,
        DEFAULT_PAGE_SIZE
      );

      const branchCards: BranchCard[] = [];

      for (const branch of data) {
        const dataUrl = await generateBlurhashDataUrl(branch.blurhash, 32, 32);
        branchCards.push({
          ...branch,
          dataUrl,
        });
      }
      return (
        <Head title={title}>
          <Branch media={media} branches={branchCards} metadata={metadata} />
        </Head>
      );
    })
    .get(
      "/filter",
      async ({ db, query }): Promise<JSX.Element> => {
        const page =
          query.page < DEFAULT_CURRENT_PAGE ? DEFAULT_CURRENT_PAGE : query.page;
        const pageSize = DEFAULT_PAGE_SIZE;
        const name = query.name;

        if (!name || name.length < 3) {
          return await getPaginated(db, page, pageSize);
        }

        return await getPaginated(db, page, pageSize, name);
      },
      { ...searchValidation }
    )
);
