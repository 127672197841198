import { RedisOptions } from "ioredis";

import { config } from ".";

const THIRTY_SECONDS = 30000;
export const redisConfig: RedisOptions = {
  host: config.cache.host,
  port: config.cache.port,
  username: config.cache.username,
  password: config.cache.pass,
  tls: config.cache.tls ? {} : undefined,

  retryStrategy: () => {
    return THIRTY_SECONDS;
  },
};
