import { t } from "elysia";

export const contactSubmissionValidation = {
  body: t.Object({
    name: t.String({ minLength: 3, maxLength: 255, error: "Nama tidak valid" }),
    email: t.String({ format: "email", error: "Email tidak valid" }),
    phone: t.String({
      pattern: "^(0|62|\\+62)(21\\d{7,8}|8[1-9]\\d{7,10})$",
      error: "Nomor HP tidak valid",
    }),
    category: t.String({
      minLength: 3,
      maxLength: 255,
      regex: "collaboration|partnership",
      error: "Kategori tidak valid",
    }),
    message: t.String({
      minLength: 3,
      maxLength: 255,
      error: "Pesan tidak valid",
    }),
  }),
};
