import { Database } from "bun:sqlite";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { Paginated } from "./root";

export interface Product {
  id: string;
  name: string;
  category: "juice" | "dessert" | "food";
  price: number;
  secondaryPrice: number;
  imageUrl: string;
  blurhash: string;
  isHighlighted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export class ProductsDatabase {
  protected db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  // get by id
  getById(id: string): Product {
    const result = this.db
      .query(`SELECT * FROM products WHERE id = ?`)
      .get(id) as object;
    return toCamelCase(result) as Product;
  }

  getJuices(): Paginated<Product> {
    const results = this.db
      .query(
        `SELECT * FROM products WHERE category = 'juice' and is_highlighted = 0 ORDER BY updated_at DESC`
      )
      .all() as object[];
    const juices = results.map(toCamelCase) as Product[];

    const totalRows = this.db
      .query(
        `SELECT COUNT(*) as total FROM products WHERE category = 'juice' and is_highlighted = 0`
      )
      .get() as { total: number };

    const metadata = constructPaginationMetadata(totalRows, 0, juices.length);

    return { data: juices, metadata };
  }

  getDesserts(): Paginated<Product> {
    const results = this.db
      .query(
        `SELECT * FROM products WHERE category = 'dessert' and is_highlighted = 0 ORDER BY updated_at DESC`
      )
      .all() as object[];
    const desserts = results.map(toCamelCase) as Product[];

    const totalRows = this.db
      .query(
        `SELECT COUNT(*) as total FROM products WHERE category = 'dessert' and is_highlighted = 0`
      )
      .get() as { total: number };

    const metadata = constructPaginationMetadata(totalRows, 0, desserts.length);

    return { data: desserts, metadata };
  }

  getFoods(): Paginated<Product> {
    const results = this.db
      .query(
        `SELECT * FROM products WHERE category = 'food' and is_highlighted = 0 ORDER BY updated_at DESC`
      )
      .all() as object[];
    const foods = results.map(toCamelCase) as Product[];

    const totalRows = this.db
      .query(
        `SELECT COUNT(*) as total FROM products WHERE category = 'food' and is_highlighted = 0`
      )
      .get() as { total: number };

    const metadata = constructPaginationMetadata(totalRows, 0, foods.length);

    return { data: foods, metadata };
  }

  getFeatured(): Product[] {
    const results = this.db
      .query(
        `SELECT * FROM products WHERE is_highlighted = 1 ORDER BY updated_at DESC`
      )
      .all() as object[];
    return results.map(toCamelCase) as Product[];
  }

  // Get products paginated
  getPaginated(page: number, pageSize: number): Paginated<Product> {
    const results = this.db
      .query(`SELECT * FROM products ORDER BY updated_at DESC LIMIT ? OFFSET ?`)
      .all(pageSize, page * pageSize) as object[];
    const products = results.map(toCamelCase) as Product[];

    const totalRows = this.db
      .query(`SELECT COUNT(*) as total FROM products`)
      .get() as { total: number };

    const metadata = constructPaginationMetadata(totalRows, page, pageSize);

    return { data: products, metadata };
  }
}
