import { Html } from "@elysiajs/html";

function getPageNumbers(currentPage: number, totalPages: number) {
  const delta = 2;
  const range: (number | string)[] = [];

  for (let i = 0; i < totalPages; i++) {
    if (
      i === 0 || // First page
      i === totalPages - 1 || // Last page
      (i >= currentPage - delta && i <= currentPage + delta) // Pages around current
    ) {
      range.push(i);
    } else if (range[range.length - 1] !== "...") {
      range.push("...");
    }
  }

  return range;
}

export function Pagination({
  target,
  type,
  currentPage,
  totalPages,
}: {
  target: string;
  type: string;
  currentPage: number;
  totalPages: number;
}): JSX.Element {
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  return (
    <nav
      hx-trigger="click"
      hx-target={target}
      hx-swap="innerHTML show:window:top"
      aria-label="pagination"
      class="mx-auto"
    >
      <ul class="uk-pagination justify-center">
        <li>
          <button
            disabled={currentPage <= 0}
            hx-get={`/${type}/filter?page=${currentPage - 1}`}
            class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]"
          >
            <span uk-pagination-previous></span>
          </button>
        </li>
        {pageNumbers.map((pageNum) =>
          pageNum === "..." ? (
            <li class="uk-disabled">
              <span class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]">
                …
              </span>
            </li>
          ) : (
            <li class={currentPage === pageNum ? "uk-active" : ""}>
              {currentPage === pageNum ? (
                <span
                  aria-current="page"
                  class="bg-primary min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border !border-[#4C5424] text-[#FCFCFC] font-semibold text-[13px] leading-[17.7px]"
                >
                  {(pageNum as number) + 1}
                </span>
              ) : (
                <button
                  hx-get={`/${type}/filter?page=${pageNum}`}
                  class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]"
                >
                  {(pageNum as number) + 1}
                </button>
              )}
            </li>
          )
        )}
        <li>
          <button
            disabled={currentPage >= totalPages - 1}
            hx-get={`/${type}/filter?page=${currentPage + 1}`}
            class="bg-[#FCFCFC] min-w-8 w-8 h-8 flex justify-center items-center rounded-[8px] border border-[#FCFCFC] text-dark-grey font-semibold text-[13px] leading-[17.7px]"
          >
            <span uk-pagination-next></span>
          </button>
        </li>
      </ul>
    </nav>
  );
}
