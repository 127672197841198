import { Server } from "bun";
import { Context } from "elysia";
import { redisClient } from "../cache/redisClient";
import { config } from "../config";
import { TooManyRequestsError } from "../exceptions";
import { Logger } from "./logger";

const SECONDS_TOKEN = "EX";

export const rateLimiter = async ({
  request,
  server,
}: Context): Promise<{ statusCode: number; message: string }> => {
  const clientId = getClientId({ request, server });
  if (!clientId)
    return {
      statusCode: 400,
      message: "Unable to determine client ID",
    };

  const key = `rate-limiter:${clientId}`;
  const currentCount = await redisClient.incr(key);

  if (currentCount === 1) {
    // Set expiration for this key only on the first request
    await redisClient.expire(key, config.network.windowMs / 1000);
  }

  if (currentCount > config.network.maxRequests) {
    // Block the request, log warning, and optionally lockout
    Logger.error("A potential DDoS attack exceeds the rate limit!", {
      error: {
        path: request.url,
        code: "rate_limit_exceeded",
      },
      clientAddress: clientId,
    });
    return {
      statusCode: 429,
      message: "Too many requests. Please try again later.",
    };
  }

  return { statusCode: 200, message: "OK" };
};

export const checkMutationAttempts = async (
  request: Request,
  server: Server | null,
  actionKey: string,
  lockoutDuration?: number
) => {
  const clientId = getClientId({ request, server });

  const attemptsKey = `${config.cache.mutationAttemptsKeyPrefix}${actionKey}:${clientId}`;
  const isLocked = await redisClient.get(attemptsKey);
  if (isLocked) {
    Logger.error(
      "The rate limit is exceeded, and a potential DDoS attack for mutation attempts exists!",
      {
        clientAddress: clientId,
      }
    );
    throw new TooManyRequestsError(
      "Oops! You're doing that too quickly. Please try again later."
    );
  }

  await redisClient.set(
    attemptsKey,
    "1",
    SECONDS_TOKEN,
    lockoutDuration ? lockoutDuration : config.cache.mutationLockoutDuration
  );
};

const getClientId = ({
  request,
  server,
}: {
  request: Request;
  server: Server | null;
}): string => {
  const xForwardedFor = request.headers.get("CF-Connecting-IP");
  if (xForwardedFor) {
    return xForwardedFor;
  }

  const clientAddress = server?.requestIP(request)?.address;
  if (clientAddress) {
    return clientAddress;
  }

  let reason = "unknown";
  if (!request) {
    reason = "request is undefined";
  } else if (!server) {
    reason = "server is null";
  } else if (!server.requestIP(request)) {
    reason = ".requestIP() returns null";
  } else if (!server.requestIP(request)?.address) {
    reason = ".requestIP()?.address returns undefined";
  }

  Logger.warn(`Failed to get client IP address for rate limiting: ${reason}`);
  return "";
};
