import { Database } from "bun:sqlite";
import { ulid } from "ulid";

export interface ContactSubmission {
  id: string;
  name: string;
  email: string;
  phone: string;
  category: SubmissionCategory;
  message: string;
  isEmailSent: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export type SubmissionCategory = "collaboration" | "partnership";

export class ContactSubmissionsDatabase {
  protected db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  create({
    name,
    email,
    phone,
    category,
    message,
  }: {
    name: string;
    email: string;
    phone: string;
    category: SubmissionCategory;
    message: string;
  }): string {
    const id = ulid();
    const now = new Date().toISOString();
    this.db
      .query(
        `INSERT INTO contact_submissions (id, name, email, phone, category, message, created_at, updated_at) VALUES (?, ?, ?, ?, ?, ?, ?, ?) RETURNING id`
      )
      .get(ulid(), name, email, phone, category, message, now, now);

    return id;
  }
}
