import { InternalServerError } from "elysia";
import * as Minio from "minio";
import { config } from "../../config";

const minioClient = new Minio.Client({ ...config.minio });

const presignedGetObject = async (
  objectName: string,
  expiry?: number
): Promise<string> => {
  return minioClient
    .presignedGetObject(
      config.minio.bucketName,
      objectName,
      expiry ? expiry : config.minio.presignedUrlDuration
    )
    .then((url: string) => url)
    .catch((_: Error) => {
      throw new InternalServerError(
        "Sorry, there is something wrong when fetching file"
      );
    });
};

const isResourceExist = async (objectName: string): Promise<boolean> => {
  return minioClient
    .statObject(config.minio.bucketName, objectName)
    .then(() => true)
    .catch(() => false);
};

export default {
  presignedGetObject,
  isResourceExist,
};
