import { Html } from "@elysiajs/html";
import { Footer } from "../components/footer";
import { Image } from "../components/image";
import { Navbar } from "../components/navbar";
import { Section } from "../components/section";
import { ProductMedia } from "../services/product";
import { formatToRupiah } from "../utils/string";
import { Banners, MediaProps } from "../components/banner";
import BackdropEllipse from "../components/backdrop";
import EmptyState from "../components/emptyState";
import { cn } from "../utils/classname";

export const ProductCard = ({ products }: { products: ProductMedia[] }) => {
  if (products.length <= 0) {
    return (
      <EmptyState
        customClass="mt-5"
        title="Produk Belum Tersedia"
        message="Saat ini belum ada produk di kategori ini. Silakan jelajahi produk kami lainnya!"
        icon={
          <img
            src="/public/images/icons/empty-product.svg"
            alt=""
            width="136"
            height="121"
          />
        }
      />
    );
  }

  return (
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6 w-full">
      {products.map((product) => (
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 w-full shadow-8px md:shadow-none p-3 md:p-0 rounded-[20px] bg-white">
          <div class="relative group" style="aspect-ratio: 1/1;">
            <Image
              url={product.image.url}
              dataUrl={product.image.dataUrl}
              alt={product.image.alt}
              width={368}
              height={368}
              dimensionType="fluid"
              customClass="rounded-[5px] md:rounded-[20px] w-full"
            />
            <div class="uk-overlay uk-position-cover hidden group-hover:md:block bg-black opacity-80 rounded-[20px]" />
            <div class="uk-overlay uk-position-cover hidden group-hover:md:flex flex-col text-center justify-center text-white rounded-[20px]">
              <h3 class="font-bold text-lg lg:text-[28px] lg:leading-9" safe>
                {product.name.replace("(", "\n(")}
              </h3>
              {product.category === "juice" && product.secondaryPrice > 0 ? (
                <>
                  <span class="text-sm lg:text-2xl" safe>
                    (K) {formatToRupiah(product.price)}
                  </span>
                  <span class="text-sm lg:text-2xl" safe>
                    (B) {formatToRupiah(product.secondaryPrice)}
                  </span>
                </>
              ) : (
                <span class="text-sm lg:text-2xl" safe>
                  {formatToRupiah(product.price)}
                </span>
              )}
            </div>
          </div>
          <div class="block md:!hidden pt-1.5 md:px-9 md:py-6 text-dark-grey text-center md:text-left">
            <h3 class="font-bold text-lg lg:text-[28px] lg:leading-9" safe>
              {product.name.replace("(", "\n(")}
            </h3>
            {product.category === "juice" && product.secondaryPrice > 0 ? (
              <>
                <span class="text-sm lg:text-2xl" safe>
                  (K) {formatToRupiah(product.price)}
                </span>
                <br />
                <span class="text-sm lg:text-2xl" safe>
                  (B) {formatToRupiah(product.secondaryPrice)}
                </span>
              </>
            ) : (
              <span class="text-sm lg:text-2xl" safe>
                {formatToRupiah(product.price)}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export function Menu({
  featuredProducts,
  juicesProducts,
  dessertsProducts,
  media,
}: {
  featuredProducts: ProductMedia[];
  juicesProducts: ProductMedia[];
  dessertsProducts: ProductMedia[];
  media: MediaProps[];
}): JSX.Element {
  return (
    <div class="relative flex flex-col h-full min-h-screen overflow-hidden">
      <div class="fixed inset-0 -z-20">
        <div class="absolute inset-0 backdrop-blur-[1000px]" />
      </div>
      <div class="flex bg-white">
        <Navbar activeRoute="/menus" />
      </div>

      <main
        class={cn("flex-1 flex flex-col pb-10 md:pb-[188px]", {
          "min-h-[500px] lg:min-h-[550px]": !media.length,
          "min-h-[700px] lg:min-h-[1000px]": !!media.length,
        })}
      >
        {media.length > 0 && (
          <section class="min-h-[200px]">
            <Banners media={media} />
          </section>
        )}
        <Section customClass="flex flex-col sm:px-12 md:py-0 gap-6 md:gap-10 items-center text-primary-foreground">
          <h2 class="text-center font-bold md:font-semibold text-[28px] leading-9 md:text-4xl md:leading-10">
            Menu Baru
          </h2>
          {featuredProducts.length > 0 ? (
            <div class="grid grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-full">
              {featuredProducts.map((featured) => (
                <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 w-full shadow-8px p-3 md:p-0 rounded-[20px] bg-white">
                  <div style="aspect-ratio: 1/1;">
                    <Image
                      url={featured.image.url}
                      dataUrl={featured.image.dataUrl}
                      alt={featured.image.alt}
                      width={368}
                      height={368}
                      dimensionType="fluid"
                      customClass="rounded-[5px] md:rounded-t-[20px] md:rounded-b-none w-full"
                    />
                  </div>
                  <div class="pt-1.5 md:px-9 md:py-6 text-dark-grey text-center md:text-left">
                    <h3
                      class="font-bold text-lg md:text-[28px] md:leading-9"
                      safe
                    >
                      {featured.name.replace("(", "\n(")}
                    </h3>
                    {featured.category === "juice" &&
                    featured.secondaryPrice > 0 ? (
                      <>
                        <span class="text-sm md:text-2xl" safe>
                          (K) {formatToRupiah(featured.price)}
                        </span>
                        <br />
                        <span class="text-sm md:text-2xl" safe>
                          (B) {formatToRupiah(featured.secondaryPrice)}
                        </span>
                      </>
                    ) : (
                      <span class="text-sm md:text-2xl" safe>
                        {formatToRupiah(featured.price)}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <EmptyState
              customClass="mt-10"
              title="Produk Belum Tersedia"
              message="Saat ini belum ada produk di kategori ini. Silakan jelajahi produk kami lainnya!"
              icon={
                <img
                  src="/public/images/icons/empty-product.svg"
                  alt=""
                  width="136"
                  height="121"
                />
              }
            />
          )}
        </Section>
        <Section customClass="flex flex-col sm:px-12 md:py-0 gap-5 items-center text-primary-foreground">
          <h2 class="text-center font-bold md:font-semibold text-[28px] leading-9 md:text-4xl md:leading-10">
            Koleksi Jus
          </h2>
          <ProductCard products={juicesProducts} />
        </Section>
        <Section customClass="flex flex-col sm:px-12 md:py-0 gap-5 items-center text-primary-foreground">
          <h2 class="text-center font-bold md:font-semibold text-[28px] leading-9 md:text-4xl md:leading-10">
            Produk Lain
          </h2>
          <ul class="uk-subnav" uk-switcher="animation: uk-animation-fade">
            <li>
              <span
                class="text-xl cursor-pointer !font-normal"
                hx-get="/menus/cards?category=dessert"
                hx-target="#another-menu-dessert"
                hx-swap="innerHTML"
                hx-trigger="click"
                hx-boost="true"
              >
                Aneka Es
              </span>
            </li>
            <li>
              <span
                class="text-xl cursor-pointer !font-normal"
                hx-get="/menus/cards?category=food"
                hx-target="#another-menu-food"
                hx-swap="innerHTML"
                hx-trigger="click"
                hx-boost="true"
              >
                Makanan
              </span>
            </li>
          </ul>

          <ul class="uk-switcher w-full mt-3">
            <li class="w-full" id="another-menu-dessert">
              <ProductCard products={dessertsProducts} />
            </li>
            <li class="w-full" id="another-menu-food"></li>
          </ul>
        </Section>
      </main>
      <Footer />
      {/* decoration for background */}
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[270px] -right-[250px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[961px] -left-[185px]"
      />
      <BackdropEllipse
        width={400}
        height={400}
        customClass="md:hidden top-[2322px] -right-[281px]"
      />
      <BackdropEllipse customClass="hidden md:block top-[2939px] -left-[241px]" />
      <BackdropEllipse customClass="hidden md:block top-[2322px] -right-[348px]" />
      <BackdropEllipse customClass="hidden md:block top-[961px] -left-[185px]" />
      <BackdropEllipse customClass="hidden md:block top-[270px] -right-[317px]" />
    </div>
  );
}
