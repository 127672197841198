import { cors } from "@elysiajs/cors";
import { html } from "@elysiajs/html";
import { serverTiming } from "@elysiajs/server-timing";
import { staticPlugin } from "@elysiajs/static";
import { Context, Elysia } from "elysia";
import { ulid } from "ulid";
import { branchController } from "./api/branch";
import { careerController } from "./api/career";
import { contactController } from "./api/contact";
import { fileStorageController } from "./api/fileStorage";
import { homeController } from "./api/index";
import { investController } from "./api/invest";
import { menuController } from "./api/menu";
import { notFoundController } from "./api/notFound";
import { config } from "./config";
import { errorHandler } from "./exceptions";
import { Logger } from "./lib/logger";
import { LogUtil } from "./lib/logger/logUtil";
import { rateLimiter } from "./lib/rateLimiter";
import {
  constructSecurityHeaders,
  Environment,
  HttpStatusCode,
} from "./utils/constants";

const rateLimitHandler = async (
  set: Context["set"],
  statusCode: number,
  message: string
) => {
  set.headers = {
    "Content-Type": "text/plain",
  };
  return new Response(message, {
    status: statusCode,
  });
};

export const app = new Elysia()
  .use(
    serverTiming({
      allow: config.network.useHttps,
      enabled: config.network.useHttps,
    })
  )
  .use(cors(config.cors))
  .use(staticPlugin())
  .derive(rateLimiter)
  .derive(({ set }) => {
    set.headers["X-Request-Id"] = ulid();
    return {
      requestId: set.headers["X-Request-Id"],
    };
  })
  .onError(({ error, set, request, code, requestId, server, redirect }) => {
    const xForwardedFor = request.headers.get("CF-Connecting-IP");
    const clientAddress = server?.requestIP(request)?.address;
    Logger.error(error.message, {
      ...LogUtil.formatError(error, code, request.url),
      requestId,
      clientAddress: xForwardedFor || clientAddress,
    });
    return errorHandler({ error, set, redirect });
  })
  .get("/sm/:path", () => {
    const path = "build/index.js.map";
    return Bun.file(path);
  })
  .get("/robots.txt", ({ redirect }) => {
    return redirect("/public/robots.txt");
  })
  .get("/sitemap.xml", ({ redirect }) => {
    return redirect("/public/sitemap.xml");
  })
  .get("/favicon.ico", ({ redirect }) => {
    return redirect("/public/favicon.ico");
  })
  .get("/health", () => {
    return new Response("OK", { status: 200 });
  })
  .use(fileStorageController)
  .use(html())
  .use(notFoundController)
  .guard(
    {
      async beforeHandle({ statusCode, message, set, request }) {
        if (config.env !== Environment.DEVELOPMENT) {
          set.headers = constructSecurityHeaders(request.method);
        }

        if (
          statusCode === HttpStatusCode.TOO_MANY_REQUESTS ||
          statusCode === HttpStatusCode.BAD_REQUEST
        ) {
          return await rateLimitHandler(set, statusCode, message);
        }
      },
    },
    (app) =>
      app
        .use(homeController)
        .use(branchController)
        .use(menuController)
        .use(careerController)
        .use(investController)
        .use(contactController)
  );

app.listen(config.network.port, () => {
  Logger.info(
    `Server is running now! Check it out on ${config.network.baseUrl}.`
  );
});

export type App = typeof app;
