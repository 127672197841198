import { Database } from "bun:sqlite";
import { constructPaginationMetadata, toCamelCase } from "../../utils/mapper";
import { Paginated } from "./root";

export interface Branch {
  id: string;
  name: string;
  imageUrl: string;
  blurhash: string;
  address: string;
  weekdayOpeningTime: string;
  weekdayClosingTime: string;
  weekendOpeningTime: string;
  weekendClosingTime: string;
  createdAt: Date;
  updatedAt: Date;
  googleMapsUrl?: string;
}

export class BranchesDatabase {
  protected db: Database;

  constructor(_db: Database) {
    this.db = _db;
  }

  // Get branches paginated
  getPaginated(
    page: number,
    pageSize: number,
    name?: string
  ): Paginated<Branch> {
    let results: object[];
    let totalRows: { total: number };

    if (name && name.trim().length >= 3) {
      // Perform full-text search if name is provided
      const processedQuery = name
        .trim()
        .replace(/[^a-zA-Z]/g, "")
        .split(/\s+/)
        .map((word) => `${word}*`)
        .join(" ");

      results = this.db
        .query(
          `
            SELECT c.* 
            FROM branches c
            INNER JOIN branches_fts fts ON c.rowid = fts.rowid
            WHERE branches_fts MATCH ?
            ORDER BY rank
            LIMIT ? OFFSET ?
          `
        )
        .all(processedQuery, pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(
          `
            SELECT COUNT(*) as total 
            FROM branches c
            INNER JOIN branches_fts fts ON c.rowid = fts.rowid
            WHERE branches_fts MATCH ?
          `
        )
        .get(processedQuery) as { total: number };
    } else {
      // Regular pagination if no name is provided
      results = this.db
        .query(
          `SELECT * FROM branches ORDER BY updated_at DESC LIMIT ? OFFSET ?`
        )
        .all(pageSize, page * pageSize) as object[];

      totalRows = this.db
        .query(`SELECT COUNT(*) as total FROM branches`)
        .get() as { total: number };
    }

    const branches = results.map(toCamelCase) as Branch[];
    const metadata = constructPaginationMetadata(totalRows, page, pageSize);

    return { data: branches, metadata };
  }
}
