import { Html } from "@elysiajs/html";
import { cn } from "../utils/classname";

interface Props {
  width?: number;
  height?: number;
  customClass?: string;
}

export default function BackdropEllipse({
  width = 634,
  height = 634,
  customClass = "",
}: Props): JSX.Element {
  return (
    <div
      class={cn(
        "absolute bg-primary/20 -z-10 rounded-full blur-3xl",
        customClass
      )}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    />
  );
}
