import * as crypto from "crypto";

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  TOO_MANY_REQUESTS = 429,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum Environment {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
}

export enum LoggerOutput {
  FILE = "file",
  STDOUT = "stdout",
}

export const MimeType = {
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  PNG: "image/png",
  HEIC: "image/heic",
  PDF: "application/pdf",
  CSV: "text/csv",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS: "application/vnd.ms-excel",
};

export const Extension = {
  JPEG: "jpeg",
  JPG: "jpg",
  PNG: "png",
  HEIC: "heic",
  PDF: "pdf",
  CSV: "csv",
  XLSX: "xlsx",
  XLS: "xls",
};

export const HeicBrands = [
  "heic",
  "heix",
  "mif1",
  "msf1",
  "hevc",
  "hevs",
  "avif", // Some HEIC files might use AVIF
];

export type Metadata = {
  ext: string;
  mime: string;
};

export const MagicNumbers: {
  [key: string]: Metadata;
} = {
  FFD8FF: { ext: Extension.JPEG, mime: MimeType.JPEG },
  "89504E470D0A1A0A": { ext: Extension.PNG, mime: MimeType.PNG },
  D0CF11E0A1B11AE1: { ext: Extension.XLS, mime: MimeType.XLS },
  "504B0304": {
    ext: Extension.XLSX,
    mime: MimeType.XLSX,
  },
};

export const DEFAULT_PAGE_SIZE = 6;
export const DEFAULT_CURRENT_PAGE = 0;

export const constructSecurityHeaders = (
  httpMethod: string
): Record<string, string> => {
  const nonce = generateNonce();
  return {
    // Security headers
    "strict-transport-security": "max-age=31536000; includeSubDomains; preload",
    "x-content-type-options": "nosniff",
    "x-frame-options": "DENY",

    // Cache control
    "cache-control":
      httpMethod === "GET"
        ? "public, max-age=3600, s-maxage=3600, stale-while-revalidate=60, stale-if-error=60, must-revalidate"
        : "no-store, max-age=0",
    vary: "HX-Request, Content-Type, Accept-Encoding",

    // Referrer Policy
    "referrer-policy": "no-referrer",

    // Permissions Policy
    "permissions-policy": "geolocation=(), microphone=(), camera=()",

    // Content headers
    "content-type": "text/html; charset=utf-8",

    // CSP header for enhanced security
    "content-security-policy": `default-src 'self'; \
       img-src 'self' data: media.juskode.id; \
       media-src 'self' www.dropbox.com *.dl.dropboxusercontent.com; \
       script-src 'self' 'nonce-${nonce}';  \
       style-src 'self' 'unsafe-inline'; \
       object-src 'none'; \
       base-uri 'self'; \
       frame-ancestors 'none';`,
  };
};

export function generateNonce() {
  return crypto.randomBytes(16).toString("hex");
}
