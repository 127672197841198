import * as winston from "winston";
import DailyRotateFile from "winston-daily-rotate-file";

import { LoggerOutput } from "../../utils/constants";
import { LogData } from "./logData";
import { config } from "../../config";

enum LogLevel {
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
  VERBOSE = "verbose",
  DEBUG = "debug",
  SILLY = "silly",
}

export class Logger {
  static error(message: string, logData?: LogData) {
    Logger.log(LogLevel.ERROR, message, logData || {});
  }

  static warn(message: string, logData?: LogData) {
    Logger.log(LogLevel.WARN, message, logData || {});
  }

  static info(message: string, logData?: LogData) {
    Logger.log(LogLevel.INFO, message, logData || {});
  }

  static verbose(message: string, logData?: LogData) {
    Logger.log(LogLevel.VERBOSE, message, logData || {});
  }

  static debug(message: string, logData?: LogData) {
    Logger.log(LogLevel.DEBUG, message, logData || {});
  }

  static silly(message: string, logData?: LogData) {
    Logger.log(LogLevel.SILLY, message, logData || {});
  }

  static debugSql(message: string) {
    Logger.log(LogLevel.DEBUG, message, {});
  }

  private static readonly ERROR_FILENAME = "error.log";
  private static readonly LOG_FILENAME = "combined.log";

  private static readonly logger: winston.Logger = winston.createLogger({
    transports: Logger.constructTransports(),
    exitOnError: false,
  });

  private static constructTransports() {
    const transports: winston.transport[] = [];
    const outputChannels = config.logger.logOutput.split(",");
    outputChannels.forEach((outputChannel: string) => {
      if (outputChannel === LoggerOutput.STDOUT) {
        transports.push(
          new winston.transports.Console({
            level: config.logger.logLevel,
            format: winston.format.json(),
          })
        );
      } else if (outputChannel === LoggerOutput.FILE) {
        transports.push(
          this.getDailyRotateFileTransport(
            config.logger.logFolder + Logger.ERROR_FILENAME,
            LogLevel.ERROR
          )
        );
        transports.push(
          this.getDailyRotateFileTransport(
            config.logger.logFolder + Logger.LOG_FILENAME,
            config.logger.logLevel as LogLevel
          )
        );
      }
    });

    return transports;
  }

  private static getDailyRotateFileTransport(
    filename: string,
    level: LogLevel
  ) {
    return new DailyRotateFile({
      filename,
      level,
      format: winston.format.combine(
        winston.format((logEntry: winston.LogEntry) => {
          logEntry["message"] = JSON.stringify({
            ...logEntry,
            timestamp: new Date().toISOString(),
          });
          return logEntry;
        })()
      ),
    });
  }

  private static log(level: LogLevel, message: string, data: LogData) {
    if (config.env === "test") {
      return;
    }
    Logger.logger.log(level, message, {
      ...data,
      timestamp: new Date().toISOString(),
    });
  }
}
