import Database from "bun:sqlite";
import { BannersDatabase } from "./banner";
import { config } from "../../config";
import { BranchesDatabase } from "./branch";
import { ProductsDatabase } from "./product";
import { CareersDatabase } from "./career";
import { InvestmentsDatabase } from "./investment";
import { ContactSubmissionsDatabase } from "./contactSubmission";

export const db = new Database(config.db.sqlitePath, {
  strict: true,
});

export interface DatabaseModels {
  banners: BannersDatabase;
  branches: BranchesDatabase;
  products: ProductsDatabase;
  careers: CareersDatabase;
  investments: InvestmentsDatabase;
  contactSubmission: ContactSubmissionsDatabase;
}

export const databaseModels: DatabaseModels = {
  banners: new BannersDatabase(db),
  branches: new BranchesDatabase(db),
  products: new ProductsDatabase(db),
  careers: new CareersDatabase(db),
  investments: new InvestmentsDatabase(db),
  contactSubmission: new ContactSubmissionsDatabase(db),
};
